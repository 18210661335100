export const configDE = {
  'agcgw': {
    // 德国区网关
    'backurl': 'connect-dre.dbankcloud.cn',
    'url': 'connect-dre.hispace.hicloud.com'
  },
  'client': {
    'appType': '1',
    'cp_id': '5190049000027432285',
    'product_id': '736430079245857421',
    'project_id': '736430079245857421',
    'package_name': 'translation.eu.huawei'
  },
  'service': {
    'analytics': {
      'collector_url': 'datacollector-dre.dt.hicloud.com,datacollector-dre.dt.dbankcloud.cn',
      'resource_id': 'p1',
      'channel_id': ''
    },
    'search': {
      'url': 'https://search-dre.cloud.huawei.com'
    },
    'cloudstorage': {
      'default_storage': 'translation-qtznk',
      'storage_url': 'https://ops-dre.agcstorage.link'
    },
    'ml': {
      'mlservice_url': 'ml-api-dre.ai.dbankcloud.com,ml-api-dre.ai.dbankcloud.cn'
    }
  },
  'region': 'DE',
  'configuration_version': '3.0'
};

