/* eslint-disable no-undef */
import agconnect from '@agconnect/api';
import '@agconnect/cloudstorage';
import '@agconnect/auth';
import '@agconnect/instance';
import crypto from 'crypto';
import { configCN, configDE, configRU, configSG } from './cts_env/index.js';
import { mirrorCN, mirrorDE, mirrorRU, mirrorSG } from './mirror_env/index.js';
import { stCN, stDE, stRU, stSG } from './st_env/index.js';
// eslint-disable-next-line no-undef
let DEFAULT_BUCKET = '';
const zoneIdfig = getConfigZoneId();
if (zoneIdfig === 1) {
	DEFAULT_BUCKET = 'translation-ndlu9';
} else if (zoneIdfig === 3) {
	DEFAULT_BUCKET = 'translation-qtznk';
} else if (zoneIdfig === 2) {
	DEFAULT_BUCKET = 'translation-tnl68';
} else if (zoneIdfig === 8) {
	DEFAULT_BUCKET = 'translation-rdlii';
} else if (zoneIdfig === 11) {
	window.origin === 'https://developerlfdev.hwcloudtest.cn' ? DEFAULT_BUCKET = 'testst-fbqzy' : DEFAULT_BUCKET = 'test-2lrhm';
} else if (zoneIdfig === 12) {
	window.origin === 'https://developerlfdev.hwcloudtest.cn' ? DEFAULT_BUCKET = '' : DEFAULT_BUCKET = 'translation-service-eu-znyj1';
} else if (zoneIdfig === 13) {
	window.origin === 'https://developerlfdev.hwcloudtest.cn' ? DEFAULT_BUCKET = '' : DEFAULT_BUCKET = 'translation-aaa-k8bh7';
} else if (zoneIdfig === 15) {
	window.origin === 'https://developerlfdev.hwcloudtest.cn' ? DEFAULT_BUCKET = '' : DEFAULT_BUCKET = 'translation-ru-1z038';
} else {
	DEFAULT_BUCKET = '';
}

function getAgcConfig(val) {
	const zoneIdfig = val ? val : getConfigZoneId();
	let agcConfigData = '';
	if (zoneIdfig === 1) {
		agcConfigData = configCN;
	}
	if (zoneIdfig === 3) {
		agcConfigData = configDE;
	}
	if (zoneIdfig === 2) {
		agcConfigData = configSG;
	}
	if (zoneIdfig === 8) {
		agcConfigData = configRU;
	}
	if (zoneIdfig === 11) {
		window.origin === 'https://developerlfdev.hwcloudtest.cn' ? agcConfigData = stCN : agcConfigData = mirrorCN;
	}
	if (zoneIdfig === 12) {
		window.origin === 'https://developerlfdev.hwcloudtest.cn' ? agcConfigData = stDE : agcConfigData = mirrorDE;
	}
	if (zoneIdfig === 13) {
		window.origin === 'https://developerlfdev.hwcloudtest.cn' ? agcConfigData = stSG : agcConfigData = mirrorSG;
	}
	if (zoneIdfig === 15) {
		window.origin === 'https://developerlfdev.hwcloudtest.cn' ? agcConfigData = stRU : agcConfigData = mirrorRU;
	}
	return agcConfigData;
}
async function initConfig(val) {
	if (sessionStorage.getItem('tszoneId')) {
		sessionStorage.removeItem('tszoneId');
	}
	const zoneId = sessionStorage.getItem('changeSiteZoneId')
		? sessionStorage.getItem('changeSiteZoneId')
		: getConfigZoneId();
	sessionStorage.setItem('tszoneId', zoneId);
	const agConnectConfig = getAgcConfig(val);
	await AGC.callServiceAPI(
		'translation/v1/clientInfo',
		{},
		'POST',
		zoneId,
		3000,
		(res) => {
			if (res.ret.code === 0) {
				const { data } = res;
				Object.keys(data).forEach((e) => {
					agConnectConfig.client[e] = data[e];
				});
				agconnect.instance().configInstance(agConnectConfig);
				// eslint-disable-next-line no-undef
				agconnect.instance().setCustomAuthProvider(new AtProvider());
			}
		}
	);
}
function md5(data) {
	return crypto
		.createHash('md5')
		.update(data)
		.digest('hex');
}

function createGetTokenFunc() {
	const catchData = { token: '', expireAt: 0, expiration: 0 };
	const zoneId = sessionStorage.getItem('changeSiteZoneId') || getConfigZoneId();
	// eslint-disable-next-line
	return (forceRefresh) => {
		if (catchData.token && Date.now() < catchData.expireAt) {
			return Promise.resolve({ expiration: catchData.expiration, tokenString: catchData.token });
		}
		return new Promise((resolve, reject) => {
			AGC.callServiceAPI(
				'translation/v1/jwtToken',
				{ header: {} },
				'POST',
				zoneId,
				3000,
				(res) => {
					if (res.ret.code === 0) {
						catchData.token = res.data;
						catchData.expireAt = Date.now() + 4 * 60 * 1000;
						catchData.expiration = 300;
						resolve({ expiration: 300, tokenString: res.data });
					} else {
						reject(res);
					}
				},
				(err) => {
					reject(err);
				}
			);
		});
	};
}
function AtProvider() { }
AtProvider.prototype.getToken = createGetTokenFunc();
function storage(data) {
	return agconnect.cloudStorage(data);
}

function getConfigZoneId() {
	const SITELIST = [
		{ id: 1, value: 'CN', siteId: 1 },
		{ id: 3, value: 'DE', siteId: 7 },
		{ id: 2, value: 'SG', siteId: 5 },
		{ id: 8, value: 'RU', siteId: 8 }
	];
	const TESTSITELIST = [
		{ id: 11, value: 'CN', siteId: 1 },
		{ id: 12, value: 'DE', siteId: 7 },
		{ id: 13, value: 'SG', siteId: 5 },
		{ id: 15, value: 'RU', siteId: 8 }
	];
	let zoneId;
	const agcTeamCountryCode = sessionStorage.getItem('agc_team_countryCode');
	if (window.origin === 'https://developer.huawei.com') {
		SITELIST.forEach((el) => {
			if (el.value === agcTeamCountryCode) {
				zoneId = el.id;
			}
		});
		return zoneId;
	}

	TESTSITELIST.forEach((el) => {
		if (el.value === agcTeamCountryCode) {
			zoneId = el.id;
		}
	});
	return zoneId;
}

export const SITELISTDATA = [
	{ id: 1, value: 'CN', siteId: 1 },
	{ id: 3, value: 'DE', siteId: 7 },
	{ id: 2, value: 'SG', siteId: 5 },
	{ id: 8, value: 'RU', siteId: 8 }
];
export const TESTSITELISTDATA = [
	{ id: 11, value: 'CN', siteId: 1 },
	{ id: 12, value: 'DE', siteId: 7 },
	{ id: 13, value: 'SG', siteId: 5 },
	{ id: 15, value: 'RU', siteId: 8 }
];
//现网环境bucket
export const DEFAULT_BUCKET_ADDRESS = [
	{ value: 'CN', address: 'translation-ndlu9' },
	{ value: 'DE', address: 'translation-qtznk' },
	{ value: 'SG', address: 'translation-tnl68' },
	{ value: 'RU', address: 'translation-rdlii' }
];
//本地镜像环境bucket
export const TESTDEFAULT_BUCKET_ADDRESS = [
	{ value: 'CN', address: 'test-2lrhm' },
	{ value: 'DE', address: 'translation-service-eu-znyj1' },
	{ value: 'SG', address: 'translation-aaa-k8bh7' },
	{ value: 'RU', address: 'translation-ru-1z038' }
];
//ST环境bucket
export const STTESTDEFAULT_BUCKET_ADDRESS = [
	{ value: 'CN', address: 'testst-fbqzy' },
	{ value: 'DE', address: '' },
	{ value: 'SG', address: '' },
	{ value: 'RU', address: '' }
];

export default {
	initConfig,
	DEFAULT_BUCKET,
	storage,
	md5
};
