export const configCN = {
  'agcgw': {
    // 中国区网关
    'backurl': 'connect-drcn.dbankcloud.cn',
    'url': 'connect-drcn.hispace.hicloud.com'
  },
  'client': {
    'appType': '1',
    'cp_id': '260086000000068459',
    'product_id': '736430079244699527',
    'project_id': '736430079244699527',
    'package_name': 'com.huawei.g11n.translation'
  },
  'service': {
    'analytics': {
      'collector_url': 'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
      'resource_id': 'p1',
      'channel_id': ''
    },
    'search': {
      'url': 'https://search-drcn.cloud.huawei.com'
    },
    'cloudstorage': {
      'default_storage': 'translation-ndlu9',
      'storage_url': 'https://agc-storage-drcn.platform.dbankcloud.cn'
    },
    'ml': {
      'mlservice_url': 'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn'
    }
  },
  'region': 'CN',
  'configuration_version': '1.0'
};
