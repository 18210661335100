import Cookie from 'js-cookie';
import { SITELISTDATA, TESTSITELISTDATA } from '@/util/cloudstorage.js';
export const getLanguage = async() => {
  const data = {};
  let zoneId;
  const agcTeamCountryCode = sessionStorage.getItem('agc_team_countryCode');
  if (window.origin === 'https://developer.huawei.com') {
    SITELISTDATA.forEach((el) => {
      if (el.value === agcTeamCountryCode) {
        zoneId = el.id;
      }
    });
  } else {
    TESTSITELISTDATA.forEach((el) => {
      if (el.value === agcTeamCountryCode) {
        zoneId = el.id;
      }
    });
  }

  data.uid = Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
  const url = 'translation/v1/cp/getLanguages';
  return new Promise((resolve) => {
    AGC.callServiceAPI(
      url,
      { header: {}, body: data },
      'POST',
      zoneId,
      3000,
      (res) => {
        if (res.ret.code === 0) {
          resolve(res.data);
        }
      }
    );
  });
};
export const timestampToDate = (time1) => {
  function add0(m) {
    return m < 10 ? `0${m}` : m;
  }
  // 返回格式化的日期
  const time = new Date(time1);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  const s = time.getSeconds();
  return `${y}-${add0(m)}-${add0(d)} ${add0(h)}:${add0(mm)}:${add0(s)}`;
};

export const getCPTeamIdData = (teamIdVal) => {
  const header = {};
  header.teamId = teamIdVal ? teamIdVal : AGC.getUserInfo().teamId;
  const url = 'ups/user-permission-service/v1/team-info';
  return new Promise((resolve) => {
    AGC.callServiceAPI(url, { header }, 'GET', null, 3000, (res) => {
      if (res.ret.code === 0) {
        if (window.origin === 'https://developer.huawei.com') {
          SITELISTDATA.forEach((el) => {
            if (el.siteId === res.siteId) {
              resolve(el.id);
            }
          });
        } else {
          TESTSITELISTDATA.forEach((el) => {
            if (el.siteId === res.siteId) {
              resolve(el.id);
            }
          });
        }
      }
    });
  });
};

export const getSiteName = (val) => {
  let siteVal = '';
  if (window.origin === 'https://developer.huawei.com') {
    SITELISTDATA.forEach((el) => {
      if (el.id === val) {
        siteVal = el.value;
      }
    });
  } else {
    TESTSITELISTDATA.forEach((el) => {
      if (el.id === val) {
        siteVal = el.value;
      }
    });
  }
  return siteVal;
};

export const getSiteId = (val) => {
  let siteVal = '';
  if (window.origin === 'https://developer.huawei.com') {
    SITELISTDATA.forEach((el) => {
      if (el.value === val) {
        siteVal = el.id;
      }
    });
  } else {
    TESTSITELISTDATA.forEach((el) => {
      if (el.value === val) {
        siteVal = el.id;
      }
    });
  }
  return siteVal;
};

export const getZoneIdData = () => {
  let val = '';
  const teamIdVal = AGC.getUserInfo().teamId;
  getCPTeamIdData(teamIdVal).then((zoneId) => {
    val = zoneId;
  });
  return val;
};
