export const configRU = {
  'agcgw': {
    // 俄罗斯网关
    'backurl': 'connect-drru.dbankcloud.cn',
    'url': 'connect-drru.hispace.hicloud.com'
  },
  'client': {
    'appType': '1',
    'cp_id': '1340007000015774278',
    'product_id': '736430079245857415',
    'project_id': '736430079245857415',
    'package_name': 'translation.ru.huawei'
  },
  'service': {
    'analytics': {
      'collector_url': 'datacollector-drru.dt.hicloud.com,datacollector-drru.dt.dbankcloud.cn',
      'resource_id': 'p1',
      'channel_id': ''
    },
    'search': {
      'url': 'https://search-drru.cloud.huawei.com'
    },
    'cloudstorage': {
      'default_storage': 'translation-rdlii',
      'storage_url': 'https://ops-drru.agcstorage.link'
    },
    'ml': {
      'mlservice_url': 'ml-api-drru.ai.dbankcloud.com,ml-api-drru.ai.dbankcloud.cn'
    }
  },
  'region': 'RU',
  'configuration_version': '3.0'
};
