 export const stCN = {
     'agcgw': {
         // 中国区网关
         backurl: 'https://lfagcgwtest01cn.hwcloudtest.cn:15062',
         url: 'https://lfagcgwtest01.hwcloudtest.cn:15602'
     },
     'client': {
         'appType': '9999',
         'cp_id': '630086000002725809',
         'product_id': '99038248600340841',
         'project_id': '99038248600340841'
     },
     'service': {
         'analytics': {
             'collector_url': 'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
             'resource_id': 'p1',
             'channel_id': ''
         },
         'search': {
             'url': 'https://search-drcn.cloud.huawei.com'
         },
         'cloudstorage': {
             'storage_url': 'https://agc-ops-dev.hwcloudtest.cn:8443',
             'default_storage': 'testst-fbqzy'
         },
         'ml': {
             'mlservice_url': 'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn'
         }
     },
     'region': 'CN',
     'configuration_version': '3.0'
 };

