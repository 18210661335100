export const mirrorDE = {
  'agcgw': {
    // 德国网关
    backurl: 'https://lfagcgwtest01cn.hwcloudtest.cn:18062',
    url: 'https://lfagcgwtest01cn.hwcloudtest.cn:18062'
  },
  'client': {
    'appType': '9999',
    'cp_id': '4130086000001892377',
    'product_id': '243586100692577411',
    'project_id': '243586100692577411',
    'client_id': '783282821975248000'
  },
  'service': {
    'analytics': {
      'collector_url': 'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
      'resource_id': 'p1',
      'channel_id': ''
    },
    'search': {
      'url': 'https://search-drcn.cloud.huawei.com'
    },
    'cloudstorage': {
      'storage_url': 'https://agc-storage-ops-test-cn.hwcloudtest.cn',
      'default_storage': 'translation-service-eu-znyj1'
    },
    'ml': {
      'mlservice_url': 'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn'
    }
  },
  'region': 'DE',
  'configuration_version': '3.0'
};
