import Vue from 'vue';
import VueI18n from 'vue-i18n';
import AGCDesign from '@appstore/agc-design';
import ctpI18N from '@appstore/agc-i18n/lib/ctp';
import enLocale from '@appstore/agc-design/lib/locale/lang/en.js';
import zhLocale from '@appstore/agc-design/lib/locale/lang/zh-CN.js';
import ruLocale from '@appstore/agc-design/lib/locale/lang/ru-RU.js';
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('agc_lang') || localStorage.getItem('agc_lang_site') || 'cn',
  messages: {
    en: Object.assign(enLocale, ctpI18N.en_US),
    cn: Object.assign(zhLocale, ctpI18N.zh_CN),
    ru: Object.assign(ruLocale, ctpI18N.ru_RU)
  }
});
Vue.use(AGCDesign, {
  i18n: (key, value) => i18n.t(key, value)
});
export default i18n;
