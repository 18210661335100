
import Vue from 'vue';
import VueRouter from 'vue-router';

const order = () => import('../component/order/order.vue');
const ordermain = () => import('../component/order/main.vue');
const supplierMain = () => import('../component/supplier/main.vue');
const cfmOrder = () => import('../component/order/confirm_order.vue');
const uorderlist = () => import('../component/order/u_orderlist.vue');
const sorderlist = () => import( '../component/supplier/s_orderlist.vue');
const squality = () => import('../component/supplier/quality.vue');
const backstage = () => import('../component/backstage/main.vue');
const backorderlist = () => import('../component/backstage/orderlist.vue');
const qualitytrace = () => import('../component/backstage/qualitytrace.vue');
const statistical = () => import('../component/backstage/statistical.vue');
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const routes = [
  {
    // CP端
    name: 'order',
    path: '/cp',
    component: ordermain,
    children: [
      {
        path: 'create',
        component: order
      },
      {
        path: 'list',
        component: uorderlist
      },
      {
        // 确认订单
        name: 'cfm_order',
        path: 'cfm_order',
        component: cfmOrder
      }
    ]
  },
  {
    // 后台管理
    name: 'backstage',
    path: '/backstage',
    component: backstage,
    children: [
      {
        // 订单管理
        path: 'bklist',
        component: backorderlist
      },
      {
        // 质量确认记录
        path: 'qctrace',
        component: qualitytrace
      },
      {
        // 统计管理
        path: 'statistic',
        component: statistical
      }
    ]
  },
  {
    name: 'SP',
    path: '/sp',
    component: supplierMain,
    children: [
      {
        // SP端
        name: 'solist',
        path: 'list',
        component: sorderlist
      },
      {
        // 质量确认管理
        name: 's_quality',
        path: 'quality',
        component: squality
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'hash',
  routes
});

export default router;
