export const configSG = {
  'agcgw': {
    // 新加坡网关
    'backurl': 'connect-dra.dbankcloud.cn',
    'url': 'connect-dra.hispace.hicloud.com'
  },
  'client': {
    'appType': '1',
    'cp_id': '2640065000000595632',
    'product_id': '736430079245857982',
    'project_id': '736430079245857982',
    'package_name': 'translation.aaa.huawei'
  },
  'service': {
    'analytics': {
      'collector_url': 'datacollector-dra.dt.hicloud.com,datacollector-dra.dt.dbankcloud.cn',
      'resource_id': 'p1',
      'channel_id': ''
    },
    'search': {
      'url': 'https://search-dra.cloud.huawei.com'
    },
    'cloudstorage': {
      'default_storage': 'translation-tnl68',
      'storage_url': 'https://ops-dra.agcstorage.link'
    },
    'ml': {
      'mlservice_url': 'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn'
    }
  },
  'region': 'SG',
  'configuration_version': '3.0'
};
