/* jshint esversion: 6 ,node:true,mocha:true*/
import Vue from 'vue';
import App from './app.vue';
import router from './router';
import i18n from './i18n/i18n';
// 引入AGC
import AGCDesign from '@appstore/agc-design';
import '@appstore/agc-design/lib/agc-design.css';
import Cookie from 'js-cookie';
import agc from './util/cloudstorage.js';
import { errorLogHandler } from '@appstore/agc-utils';

// 错误日志上报
Vue.use(errorLogHandler);
agc.initConfig();
Vue.prototype.$agc = agc;
Vue.use(Cookie);
Vue.use(AGCDesign, { iframeSelector: '#mainIframeView' });

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');
