<template>
  <div id="elcontainer" style="height:100vh">
    <div style="height:100%;background-color:#f6f6f6;">
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import { getCPTeamIdData } from './util/common.js';
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
      agcUid: ''
    };
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.agcUid =
      Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
    const topurl = window.top.location.href;
    if (topurl.includes('/myApp/')) {
      this.$router.push({ path: '/cp/list' });
    } else {
      sessionStorage.removeItem('agc_translation_spid');
      getCPTeamIdData().then(zoneId => {
        AGC.callServiceAPI(
          'ups/user-permission-service/v2/manage/service-provider',
          {},
          'GET',
          zoneId,
          3000,
          (res) => {
            const resCopy = Object.assign({}, res);
            resCopy.sp ? resCopy.sp : (resCopy.sp = {});
            const spId = resCopy.sp.spId ? resCopy.sp.spId : resCopy.sp.id;
            sessionStorage.setItem('agc_translation_spid', spId);
            sessionStorage.setItem('agc_siteId', resCopy.sp.country);
            sessionStorage.removeItem('agc_translation_power');
            sessionStorage.removeItem('projectInfo');
            sessionStorage.removeItem('productInfo');
            sessionStorage.removeItem('appInfo');
            this.$router.push({ path: '/sp/list' });
          },
          (err) => {
            this.$router.push({ path: '/sp/list' });
            return err;
          }
        );
      });
    }
  },
  methods: {
    // 页面刷新
    reload() {
      document.cookie = `agcTeamId='';`;
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>

<style scoped>
#elcontainer /deep/ .el-main {
  padding: 0px 0px;
}
#elcontainer /deep/ .el-header {
  padding: 0;
}
#elcontainer /deep/ .el-message-box__wrapper:focus {
  outline: none;
}
</style>

<style>
.el-message-box__wrapper:focus {
  outline: none;
}
</style>
