export const mirrorCN = {
  'agcgw': {
    // 中国区网关
    backurl: 'https://lfagcgwtest01cn.hwcloudtest.cn:18062',
    url: 'https://lfagcgwtest01cn.hwcloudtest.cn:18062'
  },
  'client': {
    'appType': '9999',
    'cp_id': '4130086000001892377',
    'product_id': '258913027873090621',
    'project_id': '258913027873090621',
    'client_id': '588849559799606528'
  },
  'service': {
    'analytics': {
      'collector_url': 'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
      'resource_id': 'p1',
      'channel_id': ''
    },
    'search': {
      'url': 'https://search-drcn.cloud.huawei.com'
    },
    'cloudstorage': {
      'storage_url': 'https://agc-storage-ops-test-cn.hwcloudtest.cn',
      'default_storage': 'test-2lrhm'
    },
    'ml': {
      'mlservice_url': 'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn'
    }
  },
  'region': 'CN',
  'configuration_version': '3.0'
};
